import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { IAdAccount } from '../../modules/BrandsReporting/components/ReportRequest/types';
import { DateFilterIdx } from '../../modules/BrandAdAccounts/components/constants';
import { v4 } from 'uuid';
import { RegexConstants } from '../../constants/regex.constants';

const initialState = {
  initialBrandAdAccounts: [] as Array<any>,
  filteredDisableItemsIds: [] as Array<any>,
  brandAdAccounts: [] as Array<any>,
  filteredBrandAdAccounts: [] as Array<any>,
  brandActiveOffers: [] as Array<string>,
  brandManagers: [] as Array<string>,
  selectedManagers: [] as Array<string>,
  sourceAccountActiveOffers: [] as Array<string>,
  searchingCampaignLoading: false,
  errorMessageCampaignIdSearch: '',
  disableEnterBtn: false,
  objectiveIsLead: null,
  activeOffer: null as IAdAccount | null,
  isTouchedFilter: false,
  isSelectedAll: false,
  dateFilterMode: 0,
  performanceStatus: [] as Array<string>,
  prevSelectedIds: [] as Array<string>,
  campaignIdSearchedValue: '',
  startDuplicationSuccess: false,
  checkedBrandAdAccounts: {} as { [key: number]: any },
  selectedItemsLength: 0,
  checkedCampaignsWithEmptyBudgetLeadIdLength: 0,
  isNamingConventionError: false,
  franchisorErrorId: 0,
  duplicationWarnings: null as { [key: string]: string } | null,
  syncNamesPattern: null as { [key: string]: string } | null,
  namingConventionErrors: [] as Array<any>,
};

function updateSelectedItems(primaryList: Array<any>, secondaryList: Array<any>) {
  const selectedIds = secondaryList
    .filter(item => item.checked)
    .map(item => item.fb_account_id);


  return primaryList.map((item) => {
    if (selectedIds.includes(item.fb_account_id)) {
      return {
        ...item,
        checked: true,
      };
    }
    return {
      ...item,
    };
  });
}

function areAllSelectedIdsPresent(ids: Array<number>, selectedList: Array<number>) {
  if (!selectedList.length) {
    return false;
  }
  return selectedList.every(id => ids.includes(id));
}


export const brandAdAccountsSlice = createSlice({
  name: 'brandAdAccounts',
  initialState,
  reducers: {
    setManager: (state, action) => {
      const filteredAppliedActiveOffer =
                cloneDeep(state.brandAdAccounts)
                  .filter(accOff => accOff.active_offers.includes(state.activeOffer?.name));


      const wthPerformanceStatusList = cloneDeep(state.activeOffer ? filteredAppliedActiveOffer : state.brandAdAccounts)
        .filter(accOff =>
          cloneDeep(state.performanceStatus)
            .includes(accOff[DateFilterIdx[state.dateFilterMode]]));


      const filteredWthManager = cloneDeep(
        (state.activeOffer || Boolean(state.dateFilterMode)) ?
          Boolean(state.dateFilterMode) ? wthPerformanceStatusList : filteredAppliedActiveOffer : state.brandAdAccounts)
        .filter(item =>
          Boolean(item.managers.some((manager: string) => action.payload.includes(manager))));


      const selectedIdsBrandAdAccounts =
                cloneDeep(state.brandAdAccounts).filter(itm => itm.checked)
                  .map(camp => camp.fb_account_id);

      const selectedIdsFilteredActiveOffer = filteredWthManager
        .filter(itm => itm.checked)
        .map(camp => camp.fb_account_id);


      const removeDisableId = filteredWthManager
        .filter(itm => !cloneDeep(state.filteredDisableItemsIds).includes(itm.fb_account_id));


      return {
        ...state,
        selectedManagers: [...action.payload],
        isTouchedFilter: true,
        filteredBrandAdAccounts: filteredWthManager,
        isSelectedAll: removeDisableId.length === selectedIdsFilteredActiveOffer.length ?
          areAllSelectedIdsPresent(selectedIdsBrandAdAccounts, selectedIdsFilteredActiveOffer) : false,
      };
    },
    setCheckedBrandAdAccounts: (state, action) => {
      const checkedCampaignsWithLeadObjective = Object.values(
        action.payload,
      )?.filter((campaigns: any) => (
        (campaigns?.checked &&
                    (!campaigns?.percentage || !campaigns?.lead_gen_form_id)) ||
                (campaigns?.checked &&
                    (campaigns?.percentage < 24 || !campaigns?.lead_gen_form_id))
      )).length;


      const checkedCampaignsNotLeadObjective = Object.values(
        action.payload,
      )?.filter((campaigns: any) => (
        (campaigns?.checked && !campaigns?.percentage) ||
                (campaigns?.checked &&
                    !RegexConstants.Only1To100Numbers.test(campaigns?.percentage))
      )).length;

      const checkedCampaignsWithEmptyBudgetLeadIdLength = state.objectiveIsLead
        ? checkedCampaignsWithLeadObjective
        : checkedCampaignsNotLeadObjective;

      const selectedItemsLength = Object.values(state.brandAdAccounts)?.filter(
        (campaign: any) => campaign?.checked,
      ).length;


      return {
        ...state,
        checkedBrandAdAccounts: cloneDeep(action.payload),
        selectedItemsLength,
        checkedCampaignsWithEmptyBudgetLeadIdLength,
      };
    },
    setStartDuplicationSuccess: (state, action) => ({
      ...state,
      startDuplicationSuccess: action.payload,
    }),
    setCampaignIdSearchedValue: (state, action) => ({
      ...state,
      campaignIdSearchedValue: action.payload,
    }),
    setBrandAdAccounts: (state, action) => {
      const convertDataSelectable = cloneDeep(action.payload.brand_ad_accounts)
        .map((campaign: any) => ({
          ...campaign,
          checked: false,
          id: v4(),
        }));

      const filteredDisableItemsIds = cloneDeep(action.payload.brand_ad_accounts)
        .map((campaign: any) => {
          if (
            !((action.payload.source_campaign_objective_is_lead && campaign.lead_gen_forms?.length
                                && campaign.current_month_budget)
                            || (!action.payload.source_campaign_objective_is_lead && campaign.current_month_budget))
          ) {
            return campaign.fb_account_id;
          }
          return null;
        })
        .filter((itm: any) => itm);


      return {
        ...state,
        brandAdAccounts: convertDataSelectable,
        initialBrandAdAccounts: convertDataSelectable,
        filteredBrandAdAccounts: convertDataSelectable,
        objectiveIsLead: action.payload.source_campaign_objective_is_lead,
        brandManagers: action.payload.brand_managers,
        brandActiveOffers: action.payload.brand_active_offers,
        sourceAccountActiveOffers: action.payload.source_ad_account_active_offers,
        filteredDisableItemsIds,
        sourceCampaignObjectiveLead: action.payload.source_campaign_objective_is_lead,
      };
    },
    resetSelectAllCheckbox: state => ({
      ...state,
      isSelectedAll:
                state.isSelectedAll ? Boolean(state.activeOffer?.name) : false,
    }),
    resetAllSelectedList: (state) => {
      const selectedAllList = cloneDeep(state.isTouchedFilter ?
        state.filteredBrandAdAccounts : state.initialBrandAdAccounts)
        .map(campaign => ({ ...campaign, checked: false }));


      return {
        ...state,
        isSelectedAll: false,
        brandAdAccounts: cloneDeep(state.initialBrandAdAccounts),
        filteredBrandAdAccounts: selectedAllList,
      };
    },
    setCheckboxAll: (state, action) => {
      const selectedAllList = cloneDeep(
        action.payload.length ? action.payload : state.isTouchedFilter ?
          state.filteredBrandAdAccounts : state.brandAdAccounts)
        .map((campaign: any) => {
          if (
            !((state.objectiveIsLead && campaign.lead_gen_forms?.length && campaign.current_month_budget) ||
                            (!state.objectiveIsLead && campaign.current_month_budget))
          ) {
            return {
              ...campaign,
              checked: false,
            };
          }

          return {
            ...campaign,
            checked: !state.isSelectedAll,
          };
        });

      const selectedIds = selectedAllList.map((item: any) => {
        if (item.checked) {
          return item.fb_account_id;
        }
        return null;
      }).filter((itm: any) => Boolean(itm));

      const getAlreadySelectedIds = cloneDeep(state.brandAdAccounts).map((item) => {
        if (item.checked) {
          return item.fb_account_id;
        }
        return null;
      })
        .filter(itm => Boolean(itm));

      const selectedIdsUnCheck = selectedAllList.map((itm: any) => itm.fb_account_id);

      const arrList = selectedIds.length ? getAlreadySelectedIds
        : cloneDeep(getAlreadySelectedIds).filter(id => !selectedIdsUnCheck.includes(id));


      const selectedItems = cloneDeep(state.brandAdAccounts).map((campaign) => {
        if ([...selectedIds, ...arrList].includes(campaign.fb_account_id)) {
          return {
            ...campaign,
            checked: true,
          };
        }
        return {
          ...campaign,
          checked: false,
        };
      });

      const combinationCheckedData = !state.isTouchedFilter ? state.isSelectedAll ?
        selectedAllList : selectedItems
        : selectedItems;

      const objChecked: { [key: number]: any } = { ...state.checkedBrandAdAccounts };


      selectedItems.forEach((itm: any) => {
        if (itm.checked) {
          objChecked[itm.fb_account_id] = {
            ...objChecked[itm.fb_account_id],
            checked: true,
            customStoreId: itm.custom_store_id,
          };
        } else {
          delete objChecked[itm.fb_account_id];
        }
      });

      return {
        ...state,
        brandAdAccounts: combinationCheckedData,
        filteredBrandAdAccounts: selectedAllList,
        isSelectedAll: !state.isSelectedAll,
        isTouchedFilter: true,
        prevSelectedIds: selectedIds,
        checkedBrandAdAccounts: objChecked,
        selectedItemsLength: Object.keys(objChecked).length,
      };
    },
    resetAfterSearch: (state) => {
      const filteredAppliedActiveOffer =
                cloneDeep(state.brandAdAccounts)
                  .filter(accOff => accOff.active_offers.includes(state.activeOffer?.name));

      const wthActiveOffList = cloneDeep(state.brandAdAccounts)
        .filter(accOff =>
          state.performanceStatus.includes(accOff[DateFilterIdx[state.dateFilterMode]]));

      const filteredActiveOffer = cloneDeep(state.activeOffer?.name ?
        filteredAppliedActiveOffer : wthActiveOffList)
        .filter(accOff => state.performanceStatus
          .includes(accOff[DateFilterIdx[state.dateFilterMode]]));

      const combineData =
                (state.activeOffer?.name && state.performanceStatus.length) ? filteredActiveOffer :
                  state.activeOffer?.name ? filteredAppliedActiveOffer : wthActiveOffList;

      return {
        ...state,
        isTouchedFilter: state.performanceStatus.length ? true : Boolean(state.activeOffer?.name),
        filteredBrandAdAccounts: combineData,
      };
    },
    resetAllDuplicationData: state => ({
      ...initialState,
      campaignIdSearchedValue: state.campaignIdSearchedValue,
      startDuplicationSuccess: state.startDuplicationSuccess,
    }),
    setCheckboxChange: (state, action) => {
      const selectedCurrenItems = cloneDeep(state.brandAdAccounts)
        .map((campaign) => {
          if (campaign.id === action.payload) {
            return {
              ...campaign,
              checked: !campaign.checked,
            };
          }
          return campaign;
        });

      const selectedItemsList = selectedCurrenItems.filter(itm => itm.checked)
        .map((itm: any) =>
          ({ fbAccountId: itm.fb_account_id, customStoreId: itm.custom_store_id }));

      const checkAppliedFilterSelected = cloneDeep(state.filteredBrandAdAccounts);

      const generateCheckedList = checkAppliedFilterSelected.map(
        (campaign) => {
          // if (selectedItemsList.includes(campaign.fb_account_id)) {
          if (selectedItemsList.some(itm => itm.fbAccountId === campaign.fb_account_id)) {
            return {
              ...campaign,
              checked: true,
            };
          }
          return {
            ...campaign,
            checked: false,
          };
        },
      );

      const selectedItm = cloneDeep(state.brandAdAccounts)
        .find(campaign => campaign.id === action.payload);

      const objChecked: { [key: number]: any } = { ...state.checkedBrandAdAccounts };

      if (objChecked[selectedItm?.fb_account_id]) {
        delete objChecked[selectedItm?.fb_account_id];
      }

      selectedItemsList.forEach((itm: any) => {
        objChecked[itm.fbAccountId] = {
          ...state.checkedBrandAdAccounts[itm.fbAccountId],
          checked: true,
          customStoreId: itm.customStoreId,
        };
      });

      return {
        ...state,
        brandAdAccounts: selectedCurrenItems,
        filteredBrandAdAccounts: generateCheckedList,
        isSelectedAll: false,
        checkedBrandAdAccounts: objChecked,
        selectedItemsLength: Object.keys(objChecked).length,
      };
    },
    resetFilter: (state) => {
      const filteredActiveOffer = cloneDeep(state.brandAdAccounts)
        .filter(accOff =>
          state.performanceStatus.includes(accOff[DateFilterIdx[state.dateFilterMode]]));

      const primaryList = cloneDeep(state.initialBrandAdAccounts);
      const secondaryList = cloneDeep(state.brandAdAccounts);

      const selectedItems = updateSelectedItems(primaryList, secondaryList);

      return {
        ...state,
        filteredBrandAdAccounts:
                    state.performanceStatus.length ? filteredActiveOffer : selectedItems,
        activeOffer: null,
        isTouchedFilter: false,
        brandAdAccounts:
                    state.performanceStatus.length ? cloneDeep(state.brandAdAccounts) : selectedItems,
        isSelectedAll: false,
      };
    },
    setActiveOffer: (state, action) => {
      if (!action.payload) {
        const filteredActiveOffer = cloneDeep(state.isTouchedFilter ?
          state.filteredBrandAdAccounts : state.brandAdAccounts)
          .filter(accOff =>
            state.performanceStatus.includes(accOff[DateFilterIdx[state.dateFilterMode]]));

        return {
          ...state,
          activeOffer: null,
          isTouchedFilter: false,
          brandAdAccounts: cloneDeep(state.initialBrandAdAccounts),
          filteredBrandAdAccounts:
                        state.performanceStatus.length ? filteredActiveOffer : [],
        };
      }

      const wthPerformanceStatusList = cloneDeep(state.brandAdAccounts)
        .filter(accOff =>
          cloneDeep(state.performanceStatus)
            .includes(accOff[DateFilterIdx[state.dateFilterMode]]));

      const filteredActiveOffer = cloneDeep(state.performanceStatus.length ?
        wthPerformanceStatusList : state.brandAdAccounts)
        .filter(accOff => accOff.active_offers.includes(action.payload.name));


      const selectedIdsBrandAdAccounts =
                cloneDeep(state.brandAdAccounts).filter(itm => itm.checked)
                  .map(camp => camp.fb_account_id);

      const selectedIdsFilteredActiveOffer = filteredActiveOffer
        .filter(itm => itm.checked)
        .map(camp => camp.fb_account_id);


      const removeDisableId = filteredActiveOffer
        .filter(itm => !cloneDeep(state.filteredDisableItemsIds).includes(itm.fb_account_id));

      const filterByManager = filteredActiveOffer.filter(item =>
        Boolean(item.managers.some((manager: string) => state.selectedManagers.includes(manager))));


      return {
        ...state,
        filteredBrandAdAccounts: filterByManager,
        activeOffer: action.payload,
        isTouchedFilter: true,
        isSelectedAll: removeDisableId.length === selectedIdsFilteredActiveOffer.length ?
          areAllSelectedIdsPresent(selectedIdsBrandAdAccounts, selectedIdsFilteredActiveOffer) : false,
      };
    },
    setPerformanceStatusFilter: (state, action) => {
      const filteredAppliedActiveOffer = state.isTouchedFilter ?
        cloneDeep(state.brandAdAccounts)
          .filter(accOff => accOff.active_offers.includes(state.activeOffer?.name)) : [];

      const wthActiveOffList = cloneDeep(state.brandAdAccounts)
        .filter(accOff =>
          action.payload.performanceStatus.includes(accOff[DateFilterIdx[action.payload.dateFilterMode]]));

      const filteredActiveOffer = cloneDeep(state.activeOffer?.name ?
        filteredAppliedActiveOffer : wthActiveOffList)
        .filter(accOff => action.payload.performanceStatus
          .includes(accOff[DateFilterIdx[action.payload.dateFilterMode]]));


      const filterByManager = filteredActiveOffer.filter(item =>
        Boolean(item.managers.some((manager: string) => state.selectedManagers.includes(manager))));

      const selectedIdsBrandAdAccounts =
                cloneDeep(state.brandAdAccounts).filter(itm => itm.checked)
                  .map(camp => camp.fb_account_id);

      const selectedIdsFilteredActiveOffer = filteredActiveOffer
        .filter(itm => itm.checked)
        .map(camp => camp.fb_account_id);


      const removeDisableId = filteredActiveOffer
        .filter(itm => !cloneDeep(state.filteredDisableItemsIds).includes(itm.fb_account_id));

      return {
        ...state,
        filteredBrandAdAccounts: filterByManager,
        dateFilterMode: action.payload.dateFilterMode,
        performanceStatus: cloneDeep(action.payload.performanceStatus),
        isTouchedFilter: true,
        isSelectedAll: removeDisableId.length === selectedIdsFilteredActiveOffer.length ?
          areAllSelectedIdsPresent(selectedIdsBrandAdAccounts, selectedIdsFilteredActiveOffer) : false,
      };
    },
    resetPerformanceFilter: (state) => {
      const filteredActiveOffer = cloneDeep(state.brandAdAccounts)
        .filter(accOff => accOff.active_offers.includes(state.activeOffer?.name));

      const primaryList = cloneDeep(state.initialBrandAdAccounts);
      const secondaryList = cloneDeep(state.brandAdAccounts);

      const selectedItems = updateSelectedItems(primaryList, secondaryList);


      return {
        ...state,
        filteredBrandAdAccounts:
                    state.performanceStatus.length ? filteredActiveOffer : state.activeOffer?.name ? filteredActiveOffer : selectedItems,
        brandAdAccounts:
                    state.performanceStatus.length ? cloneDeep(state.brandAdAccounts) : selectedItems,
        dateFilterMode: 0,
        performanceStatus: [],
        isTouchedFilter: Boolean(state.activeOffer),
        isSelectedAll: false,
      };
    },
    setDuplicationNamingConvention: (state, action) => ({
      ...state,
      isNamingConventionError: true,
      franchisorErrorId: action.payload.franchisor_id,
    }),
    resetDuplicationNamingConvention: state => ({
      ...state,
      isNamingConventionError: false,
      franchisorErrorId: 0,
      duplicationWarnings: null,
      syncNamesPattern: null,
      namingConventionErrors: [],
    }),
    setDuplicationWarnings: (state, action) => ({
      ...state,
      duplicationWarnings: { info: action.payload[0].info, title: action.payload[0].title },
      syncNamesPattern: action.payload[0]?.details ? { ...action.payload[0].details } : {},
      namingConventionErrors: action.payload[0]?.errors ? [...action.payload[0].errors] : [],
    }),
    setSearchingCampaignLoading: (state, action) => {
      state.searchingCampaignLoading = action.payload;
    },
    setErrorMessageCampaignIdSearch: (state, action) => {
      state.errorMessageCampaignIdSearch = action.payload;
    },
    setDisableEnterBtn: (state, action) => {
      state.disableEnterBtn = action.payload;
    },
  },
});

export const {
  setBrandAdAccounts,
  setManager,
  setCheckedBrandAdAccounts,
  setStartDuplicationSuccess,
  resetAllSelectedList,
  resetFilter,
  setCheckboxChange,
  setCheckboxAll,
  setPerformanceStatusFilter,
  setActiveOffer,
  setSearchingCampaignLoading,
  setErrorMessageCampaignIdSearch,
  setDisableEnterBtn,
  resetPerformanceFilter,
  resetAllDuplicationData,
  setDuplicationNamingConvention,
  resetSelectAllCheckbox,
  resetAfterSearch,
  setCampaignIdSearchedValue,
  setDuplicationWarnings,
  resetDuplicationNamingConvention,
} = brandAdAccountsSlice.actions;

export default brandAdAccountsSlice.reducer;
