import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import BrandAdAccount from 'modules/BrandAdAccounts/components/BrandAdAccount/BrandAdAccount';
import 'simplebar-react/dist/simplebar.min.css';
import Flex from 'modules/shared/components/general/Flex/Flex';
import ErrorMessage from 'modules/shared/components/general/ErrorMessage/ErrorMessage';
import close from 'assets/icons/close.svg';
import search from 'assets/icons/search.svg';
import CampaignsEmptyStateBySearch from './EmptyStateBySearch';
import { startCampaignDuplication } from '../../../../store/brandAdAccounts/actions';
import { useScrollTop } from '../../../../hooks/useScrollTop';
import { IAdAccount } from '../../../BrandsReporting/components/ReportRequest/types';
import CustomAutoComplete from '../../../shared/components/general/CustomAutoComplete/CustomAutoComplete';
import {
  resetAfterSearch,
  resetAllDuplicationData,
  resetAllSelectedList,
  resetFilter,
  resetSelectAllCheckbox,
  setActiveOffer,
  setCheckboxAll,
  setCheckboxChange,
  setCheckedBrandAdAccounts,
  setManager,
  setStartDuplicationSuccess,
} from '../../../../store/brandAdAccounts/brandAdAccountsSlice';
import Checkbox from '../../../shared/components/general/Checkbox/Checkbox';
import check2 from '../../../../assets/icons/check2.svg';
import Box from '@mui/material/Box';
import { brandAdAccountUseStyles } from '../BrandAdAccount/styles';
import { ChooseBrandAdAccountsUseStyles } from './ChooseBrandAdAccounts.styles';
import CustomMultiSelectAutoComplete
  from '../../../shared/components/general/CustomMultiSelectAutoComlete/CustomMultiSelectAutoComlete';
import BasicModal from '../../../shared/components/general/BasicModal/BasicModal';
import { Button } from '../../../shared/components/general/Button/Button';
import { ButtonVariants } from '../../../shared/components/general/Button/Button.types';
import { compareArraysFbAccountIds } from '../../../../constants/compareArraysFbAccountIds';
import MultiSelect from '../../../shared/components/general/MultiSelect/MultiSelect';


const ChooseBrandAdAccounts = () => {
  const dispatch = useDispatch();
  const styles = ChooseBrandAdAccountsUseStyles();
  const classes = brandAdAccountUseStyles();
  const scrollTopComponent = useScrollTop();
  const [searchValueOfCampaign, setSearchValueOfCampaign] = useState('');
  const [activeTab, setActiveTab] = useState('all');
  const [inputIsActive, setInputIsActive] = useState(false);
  const [hasBorder, setHasBorder] = useState<boolean>(false);
  const [checkedCampaignsIds, setCheckedCampaignsIds] = useState<any>([]);
  const [lastAddedId, setLastAddedIdd] = useState('');
  const [isOpenApplyAllSelectModal, setOpenApplyAllSelectModal] = useState(false);
  const [fbAccountId, setFbAccountId] = useState<number | null>(null);
  const [isOpenManagerSelect, setOpenManagerSelect] = useState(false);

  const {
    brandAdAccounts: {
      campaignIdSearchedValue,
      brandAdAccounts,
      filteredBrandAdAccounts,
      objectiveIsLead,
      brandActiveOffers,
      sourceAccountActiveOffers,
      activeOffer,
      isTouchedFilter,
      dateFilterMode,
      performanceStatus,
      selectedItemsLength,
      checkedBrandAdAccounts,
      brandManagers,
      selectedManagers,
      sourceCampaignObjectiveLead,
    },
  }: any = useSelector(state => state);


  const notReadySelectedItems = useMemo(() => Object.values(checkedBrandAdAccounts)
    .filter(
      (item: any) => {
        if (!sourceCampaignObjectiveLead) {
          if (item?.percentage && item?.checked) {
            return (
              !item?.percentage || (item?.percentage && item?.percentage < 25)
            );
          } else if (!item?.percentage && item?.checked) {
            return !item?.percentage;
          }
          return null;
        } else {
          if (item?.lead_gen_form_id && item?.checked) {
            return (
              !item?.percentage ||
                            (item?.percentage && item?.percentage < 25) ||
                            !item?.lead_gen_form_id
            );
          } else if (!item?.lead_gen_form_id && item?.checked) {
            return !item?.percentage || !item?.lead_gen_form_id;
          }
        }
        return null;
      },
    ), [checkedBrandAdAccounts, sourceCampaignObjectiveLead]);


  useEffect(
    () => () => {
      dispatch(resetAllDuplicationData());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [],
  );

  const selectedItems = useMemo(
    () => brandAdAccounts.filter((itm: any) => itm.checked)
    , [brandAdAccounts],
  );

  const arrList = (activeTab !== 'selected' ?
    (activeOffer || Boolean(dateFilterMode) ? filteredBrandAdAccounts : brandAdAccounts)
    : brandAdAccounts.filter((campaign: any) => campaign.checked));


  const searchedCampaign = arrList.filter((campaign: any) => {
    const searchLowerCase = searchValueOfCampaign.toLowerCase();
    const accountIdIncludesSearch = campaign.fb_account_id.toString().includes(searchLowerCase);
    const accountNameIncludesSearch = campaign.account_name.toLowerCase().includes(searchLowerCase);

    return accountIdIncludesSearch || accountNameIncludesSearch;
  });


  const handleOverwrite = () => {
    if (fbAccountId) {
      const obj: { [key: string]: { checked: boolean } } = {};

      Object.keys(checkedBrandAdAccounts)
        .map(id => obj[id] = {
          ...checkedBrandAdAccounts[id],
          percentage: checkedBrandAdAccounts[id]?.checked &&
                        checkedBrandAdAccounts[fbAccountId]?.percentage,
        });

      dispatch(setCheckedBrandAdAccounts(obj));
      setOpenApplyAllSelectModal(false);
      setLastAddedIdd('');
    }
  };


  const handleApplyAllSelected = (fbAccountId: number) => {
    setOpenApplyAllSelectModal(true);
    setFbAccountId(fbAccountId);
  };

  const handleActiveOfferList = (value: IAdAccount | null) => {
    if (!value) {
      dispatch(resetFilter());
      return;
    }
    dispatch(setActiveOffer(value));
  };


  const handleCheckboxAll = (searchedList: Array<any>) => {
    if (activeTab === 'selected') {
      dispatch(setCheckedBrandAdAccounts({}));
      setHasBorder(false);
      dispatch(resetAllSelectedList());
      return;
    }
    dispatch(setCheckboxAll(searchedList));
    setHasBorder(false);
  };

  const handleCheckboxChange = (id: string) =>
    dispatch(setCheckboxChange(id));


  const handleManagers = (value: Array<string>) =>
    dispatch(setManager(value));

  const optionActiveOffer = brandActiveOffers.map((itm: string, index: number) => ({
    id: index,
    name: itm,
    firstLetter: sourceAccountActiveOffers.includes(itm) ?
      'Source account active offers' : 'Brand active offers',
  }));


  const prepareDataForDuplication = () => {
    const filteredByCheckedAndPercentageWithLead = Object.fromEntries(
      Object.entries(checkedBrandAdAccounts)?.filter(
        ([_, value]: any) =>
          value?.checked && value?.percentage >= 1 && value?.lead_gen_form_id,
      ),
    );

    const filteredByCheckedAndPercentageNotLead = Object.fromEntries(
      Object.entries(checkedBrandAdAccounts)?.filter(
        ([_, value]: any) => value?.checked && value?.percentage >= 1,
      ),
    );

    const filteredByCheckedAndPercentage = objectiveIsLead
      ? filteredByCheckedAndPercentageWithLead
      : filteredByCheckedAndPercentageNotLead;

    const prepareDataForDuplication = Object.keys(filteredByCheckedAndPercentage).map(key => ({
      ad_account_id: key.split(':')[0],
      percentage: Number(checkedBrandAdAccounts[key]?.percentage),
      custom_store_id: checkedBrandAdAccounts[key]?.customStoreId,
      lead_gen_form_id: objectiveIsLead
        ? checkedBrandAdAccounts[key]?.lead_gen_form_id
        : null,
    }));

    dispatch<any>(
      startCampaignDuplication(campaignIdSearchedValue, prepareDataForDuplication, () => {
        dispatch(setStartDuplicationSuccess(true));
      }),
    );
  };

  const inputSearchStyle = {
    backgroundImage: inputIsActive || searchValueOfCampaign ? 'none' : `url(${search})`,
    padding: inputIsActive || searchValueOfCampaign ? '8px 43px 8px 16px' : '8px 50px 8px 16px',
  };

  let hasMatchingCampaigns = false;

  const startDuplicationHandler = () => {
    const checkedCampaignsIds = brandAdAccounts
      .filter((campaign: any) => campaign.checked)
      .map((item: any) => item.fb_account_id);
    setCheckedCampaignsIds(checkedCampaignsIds);

    if (activeTab === 'all') {
      if (notReadySelectedItems.length) {
        setHasBorder(true);
        return setActiveTab('selected');
      } else {
        setHasBorder(false);
        return prepareDataForDuplication();
      }
    } else {
      if (!notReadySelectedItems.length) {
        prepareDataForDuplication();
        setHasBorder(false);
      } else {
        setHasBorder(true);
      }
    }
    setSearchValueOfCampaign('');
  };

  const generateFlexStyle = () => {
    if (activeTab === 'all') {
      return 'space-between';
    } else {
      if (notReadySelectedItems.length && hasBorder) {
        return 'space-between';
      } else {
        return 'flex-end';
      }
    }
  };


  const generateSearchInput = () => {
    if (activeTab === 'all') {
      return (
        <Box sx={{ margin: '24px 0', width: '100%' }}>
          <Box display="flex" justifyContent="space-between" width={'100%'}>
            <Box display="flex" flexDirection="row" gap={'24px'}>
              <MultiSelect
                label="AS/AM: "
                options={brandManagers}
                style={{
                  width: '304px',
                  margin: '8px 0 8px 0',
                  borderRadius: '8px',
                }}
                handleChange={handleManagers}
                selected={selectedManagers}
                selectedLenght={selectedManagers?.length}
                dataLength={brandManagers.length}
                onCloseSelect={props => setOpenManagerSelect(props)}
                isSelectOpen={isOpenManagerSelect}
              />

              <CustomAutoComplete
                label={activeOffer?.name ? 'Offer:' : 'Choose offer:'}
                options={optionActiveOffer}
                handleChange={handleActiveOfferList}
                selected={activeOffer}
              />

              <CustomMultiSelectAutoComplete
                placeholder={'Choose performance status'}
                performanceStatusList={performanceStatus}
                dateFilterModeIdx={dateFilterMode}
              />

            </Box>

            <Box sx={{ paddingTop: '5px' }}>
              <button
                className={`auth-button ${styles.startDuplicationBtn}`}
                onClick={() => startDuplicationHandler()}
                disabled={!selectedItemsLength}
              >
                                Start duplication
              </button>
            </Box>
          </Box>

          <Box position="relative">
            <input
              className={styles.searchInput}
              name="campaignId"
              placeholder="Search by account name or ID"
              value={searchValueOfCampaign}
              onChange={(event) => {
                if (!event.target.value.length) {
                  setSearchValueOfCampaign(event.target.value);
                  dispatch(resetAfterSearch());
                  dispatch(resetSelectAllCheckbox());
                  return;
                }
                setSearchValueOfCampaign(event.target.value);
              }}
              style={inputSearchStyle}
              onBlur={() => setInputIsActive(false)}
              onFocus={() => setInputIsActive(true)}
            />
            {searchValueOfCampaign && (
              <IconButton
                className={styles.closeBtn}
                onClick={() => {
                  setSearchValueOfCampaign('');
                  dispatch(resetAfterSearch());
                  dispatch(resetSelectAllCheckbox());
                }}
              >
                <img src={close} alt="close" />
              </IconButton>
            )}
          </Box>
        </Box>
      );
    } else if (notReadySelectedItems.length && hasBorder) {
      return (
        <ErrorMessage
          text={'You missed a few steps, fill in to start the duplication process.'}
          style={{
            fontSize: '16px',
            lineHeight: '24px',
          }}
          iconStyle={{ width: '20px', height: '20px' }}
        />
      );
    } else {
      return;
    }
  };


  const generateContent = () => {
    const renderedCampaigns =
            (
              (activeTab !== 'selected' ?
                (searchValueOfCampaign ? searchedCampaign :
                  (isTouchedFilter || (activeOffer || Boolean(dateFilterMode)) ?
                    filteredBrandAdAccounts : brandAdAccounts))
                : brandAdAccounts.filter((campaign: any) => campaign.checked))
            )
              .map((campaign: any) => {
                hasMatchingCampaigns = true;
                return (
                  <BrandAdAccount
                    brandAdAccountsData={brandAdAccounts}
                    campaign={campaign}
                    key={campaign?.fb_account_id + campaign?.account_name}
                    percentage={checkedBrandAdAccounts[campaign?.fb_account_id]?.percentage}
                    hasBorder={hasBorder}
                    checkedCampaigns={checkedCampaignsIds}
                    handleCheckboxChange={handleCheckboxChange}
                    lastAddedId={lastAddedId}
                    setLastAddedIdd={setLastAddedIdd}
                    handleApplyAllSelected={handleApplyAllSelected}
                  />
                );
              });


    if (searchValueOfCampaign && !hasMatchingCampaigns && activeTab === 'all') {
      return (
        <CampaignsEmptyStateBySearch message="Your searched account name has not been found." />
      );
    } else if (activeTab === 'selected' && selectedItems.length === 0) {
      return (
        <CampaignsEmptyStateBySearch message="Your haven’t selected any accounts yet!" />
      );
    }


    const filteredDisableItems = searchedCampaign
      .filter((campaign: any) => (campaign.lead_gen_forms?.length && campaign?.current_month_budget));

    const allChecked = searchedCampaign.every((campaign: any) => campaign.checked === true);

    return <>
      {
        Boolean(renderedCampaigns?.length) ? renderedCampaigns[0] &&
                    <>
                      <Box className={`${classes.campaignInfoWrapper} ${styles.checkboxAll}`}>
                        <Checkbox
                          handleCheck={() =>
                            handleCheckboxAll(searchValueOfCampaign.length > 1 ? searchedCampaign : [])}
                          styles={{
                            checkboxWidth: '4.67px',
                            checkboxHeight: '2.67px',
                            beforeWidth: '24px',
                            beforeHeight: '24px',
                            backgroundColor: '#fff',
                            check: check2,
                            top: '4px',
                            left: '5px',
                          }}
                          checked={filteredDisableItems.length === selectedItems.length ?
                            compareArraysFbAccountIds(filteredDisableItems, selectedItems) :
                            (activeTab === 'selected' ? true : allChecked)}
                        />
                        <span>Select All</span>
                      </Box>
                      {renderedCampaigns}
                    </>
          :
          <CampaignsEmptyStateBySearch message="Your searched account name has not been found." />
      }
    </>;
  };


  return (
    <Flex direction="column" style={{ position: 'relative' }}>
      <Flex direction="column" className={styles.tabsWrapper}>
        <div className={styles.tabsContainer}>
          <div
            className={activeTab === 'all' ? styles.activeTab : styles.tabs}
            onClick={() => setActiveTab('all')}
          >
            <span>All</span>
            <span>
              ({
                (isTouchedFilter || (activeOffer || Boolean(dateFilterMode))) ?
                  searchValueOfCampaign.length >= 1 ? searchedCampaign.length : filteredBrandAdAccounts.length :
                  searchValueOfCampaign.length > 1 ? searchedCampaign.length : brandAdAccounts?.length
              })
            </span>
          </div>
          <div
            className={activeTab === 'selected' ? styles.activeTab : styles.tabs}
            onClick={() => {
              setSearchValueOfCampaign('');
              setActiveTab('selected');
            }}
          >
            <span>Selected </span>
            <span>({selectedItems.length})</span>
          </div>
        </div>
        <Flex
          justifyContent={generateFlexStyle()}
          style={{
            height: '72px', width: '100%', paddingBottom: '16px', position: 'relative',
            margin: activeTab === 'selected' ? '0' : '20px 0',
          }}
          alignItems="center"
        >
          <Box sx={{ display: 'flex', gap: '24px', alignItems: 'center', width: '100%' }}>
            {generateSearchInput()}
          </Box>

          {
            activeTab === 'selected' &&
                        <button
                          style={{ width: '165px', padding: 0 }}
                          className={`auth-button ${styles.startDuplicationBtn}`}
                          onClick={() => startDuplicationHandler()}
                          disabled={!selectedItemsLength}
                        >
                            Start duplication
                        </button>
          }

        </Flex>
      </Flex>
      <BasicModal
        open={isOpenApplyAllSelectModal}
        handleClose={() => setOpenApplyAllSelectModal(false)}
        style={{
          width: '610px',
          height: '250px',
          padding: '44px 60px',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        }}

      >
        <Box className={styles.confirmModalText}>
                    Previously input values will be overwritten.
        </Box>
        <Box className={styles.btnWrapper}>
          <Button variant={ButtonVariants.Secondary} handleClick={() => setOpenApplyAllSelectModal(false)}>
                        Cancel
          </Button>
          <Button
            variant={ButtonVariants.Primary}
            handleClick={handleOverwrite}
          >
                        Overwrite
          </Button>
        </Box>
      </BasicModal>
      {<div className={styles.chooseAccountsWrapper}>{generateContent()}</div>}
      {scrollTopComponent}
    </Flex>
  );
};

export default ChooseBrandAdAccounts;
